<template>
    <div class="container">
        <common-head :title="title" :is-return="true"><!-- <img slot="right" src="@/assets/imgs/icon/icon_log.png" alt="" /> --></common-head>
        <div class="user_msg">
            <div class="label_input">
                <div class="label">{{$t('tba.xianshimingcheng')}}</div>
                <div class="input"><input type="txt" :placeholder="$t('tba.qingshuruxianshimingcheng')" v-model="name" /></div>
            </div>
        </div>
        <div class="user_msg">
            <div class="xiala" @click="xiala">
                <div class="label">{{ jiaoyisuo_name }}</div>
                <div class="label1">{{ exe }}</div>
                <div class="right_img" v-if="!exe">
                    <img src="@/assets/imgs/shangla.png" alt="" v-if="xiala_status" />
                    <img src="@/assets/imgs/xiala.png" alt="" v-else />
                </div>
            </div>
            <div class="xiala_content" v-if="xiala_status">
                <div class="option_select" v-for="(item, index) in jiaoyisuo_list" :key="index" @click="choose(index)">
                    <div class="left">{{ item }}</div>
                </div>
            </div>
            <div class="xiala" @click="xiala1">
                <div class="label">{{ jiaoyifangshi }}</div>
                <div class="label1">{{ jiaoyi_type }}</div>
                <div class="right_img" v-if="!jiaoyi_type">
                    <img src="@/assets/imgs/shangla.png" alt="" v-if="xiala_status1" />
                    <img src="@/assets/imgs/xiala.png" alt="" v-else />
                </div>
            </div>
            <div class="xiala_content" v-if="xiala_status1">
                <div class="option_select" v-for="(item, index) in jiaoyi_type_list" :key="index" @click="choose1(index)">{{ item }}</div>
            </div>
            <div class="label_input1">
                <div class="label">{{$t('tba.shoufeibili')}}（%）</div>
                <div class="input"><input type="txt" :placeholder="$t('tba.qingshurushoufeibili')" v-model="radio" /></div>
            </div>
            <div class="label_input1">
                <div class="label">{{$t('tba.zhanghuzongzichan')}}（USDT)</div>
                <div class="input"><input type="txt" :placeholder="$t('tba.qingshuruzhanghuzongzichan')" v-model="fund" /></div>
            </div>
        </div>
        <div class="bottom"><div class="button" @click="Submit()">{{$t('tba.tijioashenqing')}}</div></div>
    </div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
    components: { commonHead },
    data() {
        return {
            title: this.$route.query.msg,
            name: '',
            jiaoyisuo_name: this.$t('tba.xuanzhejiaoyisuo') + ':',
            jiaoyifangshi: this.$t('tba.jiaoyifangshi') + ':',
            exe: '',
            jiaoyi_type: '',
            xiala_status: false,
            xiala_status1: false,
            jiaoyisuo_list: ['HUOBI', 'BIAN', 'OKEX', 'UNISWAP'],
            jiaoyi_type_list: [this.$t('tba.Spot'), this.$t('tba.contract')],
            brokerage_ratio: '',
            me_set_fund: '',
            radio: '',
            fund: '',
            index: '',
            index1: '',
            info: this.$route.query.info
        };
    },
    created() {
        console.log(this.$route.query.info);
        if (this.info) {
            this.name = this.$route.query.info.deposit_name;
            if (this.$route.query.info.exchange) {
                this.exe = this.$route.query.info.exchange.toUpperCase();
            }
            if (this.$route.query.info.deposit_type == 1) {
                this.jiaoyi_type = this.$t('tba.Spot');
            } else if (this.$route.query.info.deposit_type == 2) {
                this.jiaoyi_type = this.$t('tba.contract');
            }
            this.radio = this.$route.query.info.brokerage_ratio*100;
            this.fund = this.$route.query.info.me_set_fund;
        }
        this.getTrusteeshipRelevantConfig();
    },
    methods: {
        xiala() {
            this.xiala_status = !this.xiala_status;
        },
        xiala1() {
            this.xiala_status1 = !this.xiala_status1;
        },
        choose(e) {
            this.xiala_status = !this.xiala_status;
            this.exe = this.jiaoyisuo_list[e];
        },
        choose1(e) {
            this.xiala_status1 = !this.xiala_status1;
            this.jiaoyi_type = this.jiaoyi_type_list[e];
        },
        Submit() {
            if (this.info) {
                if (this.info.state == 0 || this.info.state == 1) {
                    this.modifyBufferTeacher();
                } else {
                    this.modifyApplyTrusteeshipTeacher();
                }
            } else {
                this.applyTrusteeshipTeacher();
            }
        },
        //用户修改处于0未开启、1开启状态的托管
        modifyBufferTeacher() {
            if (this.jiaoyi_type == this.$t('tba.Spot')) {
                this.index1 = 1;
            } else if (this.jiaoyi_type == this.$t('tba.contract')) {
                this.index1 = 2;
            }
            this.$post2('Deposit/Api/Index/modifyBufferTeacher', {
                id: this.info.id,
                exchange: this.exe.toLowerCase(), //0 1
                deposit_type: this.index1,
                deposit_name: this.name,
                brokerage_ratio: this.radio / 100,
                me_set_fund: this.fund
            })
                .then(res => {
                    this.$toast(this.$t('tba.xiugaichenggong'));
                    this.$router.push('/im_teacher');
                    // console.log(res);
                })
                .catch(e => {
					if(e == '收费率不合法') {
						this.$toast.fail(this.$t('tba.shoufeilvbuhefa'));
					}else {
						this.$toast.fail(e);
					}
                });
        },
        //用户修改处于审核、取消托管申请、后台拒绝托管申请状态的托管
        modifyApplyTrusteeshipTeacher() {
            if (this.jiaoyi_type == this.$t('tba.Spot')) {
                this.index1 = 1;
            } else if (this.jiaoyi_type == this.$t('tba.contract')) {
                this.index1 = 2;
            }
            this.$post2('Deposit/Api/Index/modifyApplyTrusteeshipTeacher', {
                id: this.info.id,
                exchange: this.exe.toLowerCase(), //0 1
                deposit_type: this.index1,
                deposit_name: this.name,
                brokerage_ratio: this.radio / 100,
                me_set_fund: this.fund
            })
                .then(res => {
                    this.$toast(this.$t('tba.xiugaichenggong'));
                    this.$router.push('/im_teacher');
                    // console.log(res);
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        //获取交易所，托管类型
        getTrusteeshipRelevantConfig() {
            this.$post2('Deposit/Api/Index/getTrusteeshipRelevantConfig', {})
                .then(res => {
                    // console.log(res);
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        },
        // 用户申请新套餐（即用户申请成为带单老师，用户点击原型图中新量化时提交数据的接口）
        applyTrusteeshipTeacher() {
            if (this.jiaoyi_type == this.$t('tba.Spot')) {
                this.index1 = 1;
            } else if (this.jiaoyi_type == this.$t('tba.contract')) {
                this.index1 = 2;
            }
            this.$post2('Deposit/Api/Index/applyTrusteeshipTeacher', {
                exchange: this.exe.toLowerCase(), //0 1
                deposit_type: this.index1,
                deposit_name: this.name,
                brokerage_ratio: this.radio / 100,
                me_set_fund: this.fund
            })
                .then(res => {
                    this.$router.push('./im_teacher');
                })
                .catch(e => {
                    this.$toast.fail(e);
                });
        }
    }
};
</script>

<style scoped lang="less">
.container {
    background-color: #f8f8f8;
    height: 100%;
    // height: calc(100vh + 110px);
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #ffffff;
        font-size: 14px;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    font-family: PingFang SC;
    .label_input {
        margin: 0 0 0 0.16rem;
        padding: 0.28rem 0;
        display: flex;
        align-items: center;
        .label {
            color: #333333;
            font-size: 0.3rem;
            text-align: left;
            font-weight: 500;
        }
        .input {
            margin-left: 0.32rem;
            font-size: 0.3rem;
            input {
                padding-right: 1rem;
                width: 100%;
                color: #999999;
                border: none;
            }
        }
    }
    .xiala {
        margin: 0 0.2rem;
        padding: 0.26rem 0;
        border-bottom: 0.5px solid #cccccc;
        display: flex;
        justify-content: space-between;
        .label {
            font-size: 0.3rem;
            color: #333333;
            font-weight: 500;
            text-align: left;
        }
        .label1 {
            // margin-right: 3rem;
            font-size: 0.3rem;
            color: #333333;
            font-weight: 500;
            text-align: left;
        }
    }
    .xiala_content {
        margin: 0 0.2rem;
        font-size: 0.3rem;

        .option_select {
            border-bottom: 0.5px solid #cccccc;
            padding: 0.2rem 0;
            display: flex;
            font-size: 0.3rem;
            justify-content: center;
        }
        .option_select:last-child {
            border-bottom: none;
        }
    }
    .label_input1 {
        margin: 0 0 0 0.16rem;
        padding: 0.28rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.5px solid #cccccc;
        .label {
            color: #333333;
            font-size: 0.3rem;
            text-align: left;
            font-weight: 500;
        }
        .input {
            margin-left: 0.22rem;
            font-size: 0.3rem;
            input {
                text-align: right;
                padding-right: 0.2rem;
                width: 100%;
                color: #999999;
                border: none;
            }
        }
    }
}
.user_msg:last-child {
    padding-bottom: 0.4rem;
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0.2rem;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0 0.28rem;
        width: 6.9rem;
        height: 0.88rem;
        line-height: 0.88rem;
        background-color: #5899f6;
        color: #ffffff;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.12rem;
        font-weight: 500;
    }
}
</style>
