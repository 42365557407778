import { render, staticRenderFns } from "./become_teacher.vue?vue&type=template&id=42578e10&scoped=true&"
import script from "./become_teacher.vue?vue&type=script&lang=js&"
export * from "./become_teacher.vue?vue&type=script&lang=js&"
import style0 from "./become_teacher.vue?vue&type=style&index=0&id=42578e10&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42578e10",
  null
  
)

export default component.exports